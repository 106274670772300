import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  Input,
  type SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  output,
  OnChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { RichTextEditorComponent } from '@core/components/rich-text-editor/rich-text-editor.component';
import { SelectComponent } from '@core/components/select/select.component';
import { ToggleComponent } from '@design/forms/toggle/toggle.component';

import { TextValidationRulesPropControlComponent } from './text-validation-rules-prop-control/text-validation-rules-prop-control.component';
import { CustomFormElementPropertyType, CustomFormFieldGroup } from '../../models/enums';
import { CustomFormControl, type CustomFormElementProperty } from '../../models/form-element';

@Component({
  selector: 'field-edit-form',
  templateUrl: './field-edit-form.component.html',
  styleUrls: ['./field-edit-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    FormsModule,
    SelectComponent,
    ToggleComponent,
    RichTextEditorComponent,
    TranslateModule,
    TextValidationRulesPropControlComponent,
  ],
})
export class FieldEditFormComponent implements OnChanges {
  @Input() element: CustomFormControl;
  saveChanges = output<any>();

  @ViewChild('textInput') textInputRef: TemplateRef<any>;
  @ViewChild('select') selectRef: TemplateRef<any>;
  @ViewChild('switcher') switcherRef: TemplateRef<any>;
  @ViewChild('textArea') textAreaRef: TemplateRef<any>;
  @ViewChild('multilineText') multilineTextRef: TemplateRef<any>;
  @ViewChild('validationRules') validationRulesRef: TemplateRef<any>;

  public data: Record<string, any> = {};
  public properties: CustomFormElementProperty[] = [];
  /*   public ngOnInit(): void {
    this.data = { ...this.element.data };
  } */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.element) {
      this.data = { ...this.element.data };
      setTimeout(() => {
        this.properties = (
          this.element.fieldGroup === CustomFormFieldGroup.Logic
            ? this.element.logicProperties
            : this.element.properties
        ).filter((prop) => !prop.isNotEditable);
      }, 0);
    }
  }

  public save(): void {
    this.saveChanges.emit({
      ...this.data,
    });
  }

  public getPropTemplate(prop: CustomFormElementProperty): TemplateRef<any> {
    switch (prop.type) {
      case CustomFormElementPropertyType.TextInput:
        return this.textInputRef;

      case CustomFormElementPropertyType.Select:
        return this.selectRef;

      case CustomFormElementPropertyType.Switcher:
        return this.switcherRef;

      case CustomFormElementPropertyType.TextArea:
        return this.textAreaRef;

      case CustomFormElementPropertyType.MultilineText:
        return this.multilineTextRef;

      case CustomFormElementPropertyType.TextValidationRules:
        if (this.element.fieldGroup === CustomFormFieldGroup.Public) return null;
        return this.validationRulesRef;

      default:
        return null;
    }
  }
}

import type { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function numberValidator(): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors | null => {
    const number = (control?.value?.toLowerCase() as string) || '';
    if (!number) return null;

    const match = number.match(/^[0-9]*$/);
    return match ? null : { numberInvalid: true };
  };
}

export enum CustomFormElementPropertyType {
  TextInput = 'textInput',
  Select = 'select',
  Switcher = 'switcher',
  TextArea = 'textArea',
  MultilineText = 'multilineText',
  TextValidationRules = 'textValidationRules',
}

export enum CustomFormFieldType {
  Header = 'Header',
  Label = 'Label',
  Text = 'Text',
  LongText = 'LongText',
  Image = 'Image',
  SingleSelect = 'SingleSelectList',
  MultiSelect = 'MultiSelectList',
  Contact = 'Contact',
  Location = 'Location',
  Date = 'Date',
  Number = 'Number',
}

export enum CustomFormFieldGroup {
  Basic = 'Basic',
  Private = 'Private',
  Public = 'Public',
  Confidential = 'Confidential',
  Proprietary = 'Proprietary',
  Logic = 'Logic',
  BusinessObject = 'BusinessObject',
}

export enum ValidationRuleType {
  Email = 'Email',
  Phone = 'Phone',
  AlphanumericWithSpace = 'AlphanumericWithSpace',
  Url = 'Url',
  Number = 'Number',
  Custom = 'CustomFormat',
}

@if (property(); as property) {
  <div class="field-edit-form_property">
    <label
      [class.required]="property.required"
      [for]="property.key + '-rule-type'"
    >
      Validation Rule
    </label>

    <app-select
      [growWhenOpen]="true"
      [disabled]="property.disabled"
      [ngModel]="ruleType()"
      (ngModelChange)="setRuleType($event)"
      [id]="property.key + '-rule-type'"
      placeholder="Select validation rule..."
      [options]="RULE_TYPE_OPTIONS"
    />
  </div>

  @if (isCustomRule()) {
    <label
      [class.required]="true"
      [for]="property.key + '-custom-format'"
    >
      Custom Format
    </label>

    <input
      [disabled]="property.disabled"
      [ngModel]="customFormat()"
      (ngModelChange)="setCustomFormat($event)"
      [id]="property.key + '-custom-format'"
      placeholder="Enter custom format..."
    />
  }
}

<div
  class="form-element-container_content"
  [appDraggable]="{ element: element, action: 'move' }"
  appDraggableAnchor
  appDroppable
>
  <div class="form-element-container_drag-icon">
    <img appAssetSrc="assets/svg/custom-forms/drag-icon-white.svg" />
  </div>
  <div
    class="form-element-container_element"
    [style.opacity]="element.data.visible ? 1 : 0.5"
  >
    <ng-template appFormHost></ng-template>
    @if (error) {
      <div class="form-element-container_error-message">
        {{ error | translate }}
      </div>
    }
  </div>

  <div class="form-element-container_drag-indicators">
    <div
      class="left-indicator"
      appDroppable
      [attr.customFormElementId]="element.id"
      [attr.dropDireciton]="'left'"
    ></div>
    <div class="vertical-indicators">
      <div
        class="top-indicator"
        appDroppable
        [attr.customFormElementId]="element.id"
        [attr.dropDireciton]="'top'"
      ></div>
      <div
        class="bottom-indicator"
        appDroppable
        [attr.customFormElementId]="element.id"
        [attr.dropDireciton]="'bottom'"
      ></div>
    </div>
    <div
      class="right-indicator"
      appDroppable
      [attr.customFormElementId]="element.id"
      [attr.dropDireciton]="'right'"
    ></div>
  </div>
</div>

<div class="form-element-container_buttons">
  <div
    [appFocusable]
    class="form-element-container_button"
    (click)="onEditClick($event)"
  >
    <img appAssetSrc="assets/svg/custom-forms/edit-icon.svg" />
  </div>
  <div
    [appFocusable]
    class="form-element-container_button"
    (click)="onDeleteClick($event)"
  >
    <img appAssetSrc="assets/svg/custom-forms/delete-icon.svg" />
  </div>
</div>

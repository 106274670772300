import { Validators, type ValidatorFn } from '@angular/forms';

import { alphanumericWithSpaceValidator } from '@clover/core/validators/alphanumeric-with-space.validator';
import { customPatternValidator } from '@clover/core/validators/custom-pattern.validator';
import { emailValidator } from '@clover/core/validators/email.validator';
import { phoneValidator } from '@clover/core/validators/google-phone.validator';
import { numberValidator } from '@clover/core/validators/number.validator';
import { urlValidator } from '@clover/core/validators/url.validator';

import type { TextValidationsPropertyData } from '../components/field-edit-form/text-validation-rules-prop-control/text-validation-rules-prop-control.component';
import { ValidationRuleType } from '../models/enums';

export function composeTextValidators(rules: TextValidationsPropertyData[] = []): ValidatorFn {
  const validators = rules
    .map((rule) => {
      switch (rule.ruleType) {
        case ValidationRuleType.Email:
          return emailValidator();
        case ValidationRuleType.Phone:
          return phoneValidator();
        case ValidationRuleType.AlphanumericWithSpace:
          return alphanumericWithSpaceValidator();
        case ValidationRuleType.Url:
          return urlValidator(['http', 'https'], true);
        case ValidationRuleType.Number:
          return numberValidator();
        case ValidationRuleType.Custom:
          if (!rule.customFormat) return null;
          return customPatternValidator(rule.customFormat);
        default:
          return null;
      }
    })
    .filter(Boolean);

  return Validators.compose(validators);
}

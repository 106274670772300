<label
  class="form-builder_control-label"
  [class.required]="element.data.required"
>
  <span>{{ element.data?.title || element.title }}</span>
  @if (element.isLinked) {
    <img appAssetSrc="assets/svg/custom-forms/link-icon.svg" />
  }
  @if (element.helpText) {
    <app-tooltip
      [contentTemplate]="tooltip"
      class="form-builder_tooltip"
      theme="black"
    >
      <div class="tooltip-icon"></div>
    </app-tooltip>
  }

  <ng-template #tooltip>
    <div class="form-builder_tooltip-content">{{ element.helpText }}</div>
  </ng-template>
</label>
@if (disabled) {
  <div class="form-builder_multi-select-preview">
    <span>
      {{ element.data.placeholder || '' }}
    </span>
    <img appAssetSrc="assets/svg/common/arrow-down-blue.svg" />
  </div>
}
@if (!disabled) {
  <div>
    <app-select
      [(ngModel)]="value"
      [multiselect]="true"
      [options]="selectOptions"
      [placeholder]="element.data.placeholder"
    >
    </app-select>
  </div>
}

import { Component, Input, HostBinding } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CustomFormControl } from '../../models/form-element';

@Component({
  selector: 'fb-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class SectionHeaderComponent {
  @Input() element: CustomFormControl;

  @HostBinding('style.align-items') get textAlignment() {
    switch (this.element.data.textAlignment) {
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  }

  public getPlaceholder(): string {
    if (this.element.properties) {
      return this.element.properties.find((prop) => prop.key === 'subHeaderText').placeholder;
    }

    return '';
  }
}

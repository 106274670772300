import { Component, forwardRef, ComponentFactoryResolver, Input, ViewChild, inject, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';

import { FormHostDirective, FormHostDirective as FormHostDirective_1 } from '@core/directives/form-host.directive';

import { ControlTypes } from '../../constants/form-control-types';
import { CustomFormElement, type CustomFormElementInstance } from '../../models/form-element';

@Component({
  selector: 'rendered-form-element',
  templateUrl: './rendered-form-element.component.html',
  styleUrls: ['./rendered-form-element.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RenderedFormElementComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FormHostDirective_1],
})
export class RenderedFormElementComponent implements ControlValueAccessor, OnInit {
  private readonly componentFactoryResolver = inject(ComponentFactoryResolver);
  @Input() element: CustomFormElement;
  @ViewChild(FormHostDirective, { static: true })
  appFormHost: FormHostDirective;

  public disabled: boolean;
  private instance: CustomFormElementInstance;

  public ngOnInit(): void {
    const viewContainerRef = this.appFormHost.viewContainerRef;
    const control = ControlTypes[this.element.controlType];
    if (!control) {
      return;
    }
    const factory = this.componentFactoryResolver.resolveComponentFactory(control.component);
    const componentRef = viewContainerRef.createComponent(factory);

    this.instance = componentRef.instance as CustomFormElementInstance;
    this.instance.element = this.element;

    this.instance.valueChangeFunc = (val) => {
      this.onChange(val);
      this.onTouched();
    };
  }

  private onChange = (val: any) => {};
  private onTouched = () => {};

  public get value(): any {
    return this.instance.getValue();
  }

  public set value(val: any) {
    if (this.instance && this.instance.setValue) {
      this.instance.setValue(val);
    }
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

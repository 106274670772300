<ng-container>
  <form [formGroup]="form">
    <rich-text-editor
      formControlName="text"
      readOnly="true"
      textOnly="true"
    >
    </rich-text-editor>
  </form>
</ng-container>

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

import { TooltipComponent } from '../../../core/components/tooltip/tooltip.component';
import { CustomFormElement, type CustomFormElementInstance } from '../../models/form-element';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TooltipComponent, NgxMaskDirective, FormsModule],
})
export class NumberInputComponent implements CustomFormElementInstance {
  @Input() element: CustomFormElement;
  @Input() valueChangeFunc: (value: string) => void;
  @Input() disabled: boolean;
  @Input() controlName: string;
  @ViewChild('input') input: ElementRef;

  private _value: string;

  public get value(): string {
    return this._value;
  }

  public set value(val: string) {
    const maskedValue = this.input.nativeElement.value;
    this._value = maskedValue || val;
    this.valueChangeFunc(this._value);
  }

  public getValue(): string {
    return this._value;
  }

  public setValue(value: string): void {
    this._value = `${value}`;
  }
}

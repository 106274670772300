import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { type EnumerationModel } from '@core/models/enumerationModel';

import { SectorsSelectorComponent } from '../../../core/components/sectors-selector/sectors-selector.component';
import { TooltipComponent } from '../../../core/components/tooltip/tooltip.component';
import { CustomFormElement, type CustomFormElementInstance } from '../../models/form-element';

@Component({
  selector: 'fb-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TooltipComponent, SectorsSelectorComponent, FormsModule],
})
export class SectorsComponent implements CustomFormElementInstance, OnInit {
  @Input() element: CustomFormElement;
  @Input() valueChangeFunc: (value: EnumerationModel[]) => void;
  @Input() disabled: boolean;
  @Input() controlName: string;

  private _value: EnumerationModel[];

  public get value(): EnumerationModel[] {
    return this._value;
  }

  public set value(val: EnumerationModel[]) {
    this._value = val;
    this.valueChangeFunc(val);
  }

  public ngOnInit(): void {
    if (this.disabled || !this.element.options) {
    }
  }

  public getValue(): EnumerationModel[] {
    return this._value;
  }

  public setValue(value: EnumerationModel[]): void {
    this._value = value;
  }
}

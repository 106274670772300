<form
  #formElement
  [formGroup]="form"
>
  @for (row of formRows; track row) {
    <div class="form-renderer_form-row">
      @for (field of row; track field) {
        <div class="form-renderer_form-field">
          @if (field && isFieldEditable(field)) {
            <div
              class="form-group"
              [ngClass]="{
                'has-error': showErrors && form.get('' + field.id).invalid,
              }"
            >
              <rendered-form-element
                [formControlName]="'' + field.id"
                [element]="field"
              >
              </rendered-form-element>
              <form-error-messages
                [controlName]="field.data.label"
                [showErrors]="showErrors"
                [control]="form.get('' + field.id)"
              >
              </form-error-messages>
            </div>
          }
          @if (!isFieldEditable(field)) {
            <rendered-form-element [element]="field"> </rendered-form-element>
          }
        </div>
      }
    </div>
  }
</form>

import { type UntypedFormControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';

import { EMAIL_REGEX_PATTERN } from '../constants/regex-patterns';

export function emailValidator(): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors | null => {
    const email = (control?.value?.toLowerCase() as string) || '';
    if (!email) return null;

    const match = email.match(EMAIL_REGEX_PATTERN);
    return match ? null : { emailInvalid: true };
  };
}

import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxControlValueAccessor, provideCvaCompareTo } from 'ngxtension/control-value-accessor';

import { ValidationRuleType } from '@clover/custom-forms/models/enums';
import type { CustomFormElementProperty } from '@clover/custom-forms/models/form-element';

import { SelectComponent, type SelectOption } from '../../../../core/components/select/select.component';

export interface TextValidationsPropertyData {
  ruleType: ValidationRuleType;
  customFormat?: string;
}

function textValidationsPropertyDataComparator(
  a: TextValidationsPropertyData[] | undefined,
  b: TextValidationsPropertyData[] | undefined,
): boolean {
  const [ruleA] = a || [];
  const [ruleB] = b || [];

  return ruleA?.ruleType === ruleB?.ruleType && ruleA?.customFormat === ruleB?.customFormat;
}

const RULE_TYPE_OPTIONS: SelectOption[] = [
  { value: ValidationRuleType.Email, label: 'Email' },
  { value: ValidationRuleType.Phone, label: 'Phone' },
  { value: ValidationRuleType.AlphanumericWithSpace, label: 'Alphanumeric with space' },
  { value: ValidationRuleType.Url, label: 'Url' },
  { value: ValidationRuleType.Number, label: 'Number' },
  { value: ValidationRuleType.Custom, label: 'Custom' },
];

@Component({
  selector: 'cc-text-validation-rules-prop-control',
  standalone: true,
  imports: [TranslateModule, SelectComponent, FormsModule],
  hostDirectives: [NgxControlValueAccessor],
  providers: [provideCvaCompareTo(textValidationsPropertyDataComparator, true)],
  templateUrl: './text-validation-rules-prop-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextValidationRulesPropControlComponent {
  property = input.required<CustomFormElementProperty>();

  private readonly cva =
    inject<NgxControlValueAccessor<TextValidationsPropertyData[] | undefined>>(NgxControlValueAccessor);

  // For now, we only support one rule at a time.
  // This can be extended in the future, when more precise validations are needed.
  // For example, user can provide two rules for positive integers and whole numbers at the same time.
  private readonly firstRule = computed(() => this.cva.value$()?.[0]);

  protected readonly ruleType = computed(() => this.firstRule()?.ruleType);
  protected readonly customFormat = computed(() => this.firstRule()?.customFormat);
  protected readonly isCustomRule = computed(() => this.ruleType() === ValidationRuleType.Custom);

  protected readonly RULE_TYPE_OPTIONS = RULE_TYPE_OPTIONS;

  setRuleType(ruleType: ValidationRuleType): void {
    if (ruleType === this.ruleType()) {
      this.cva.value$.set(undefined);
      return;
    }

    this.cva.value$.set([{ ruleType, customFormat: undefined }]);
  }

  setCustomFormat(customFormat: string): void {
    this.cva.value$.set([{ ruleType: ValidationRuleType.Custom, customFormat: customFormat || undefined }]);
  }
}

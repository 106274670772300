import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RichTextEditorComponent } from '../../../core/components/rich-text-editor/rich-text-editor.component';
import { CustomFormControl } from '../../models/form-element';

@Component({
  selector: 'fb-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RichTextEditorComponent],
})
export class TextComponent implements OnInit {
  @Input() element: CustomFormControl;

  public form: UntypedFormGroup;

  public ngOnInit(): void {
    this.form = new UntypedFormGroup({
      text: new UntypedFormControl(this.element.data.text),
    });
  }

  public getPlaceholder(): string {
    return this.element.properties.find((prop) => prop.key === 'text').placeholder || '';
  }
}

import { UntypedFormControl, ValidatorFn, type ValidationErrors } from '@angular/forms';

type UrlScheme = 'http' | 'https' | 'mailto' | 'ftp' | 'file' | 'gopher' | 'net.pipe' | 'net.tcp' | 'news' | 'nntp';

export function urlValidator(allowedSchemes: UrlScheme[] = ['http', 'https'], allowNoScheme = true): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors | null => {
    const url = control?.value?.toLowerCase();
    if (!url) return null; // No URL provided

    // Regular expression to parse and validate URL
    const urlPattern = /^(?:(\w+):\/\/)?([\w-]+(\.[\w-]+)+)(:[0-9]+)?(\/.*)?$/i;
    const match = url.match(urlPattern);

    // Invalid URL structure
    if (!match) return { urlInvalid: true };

    // Extract scheme
    const scheme = match[1];

    // Scheme is present, but not allowed
    if (scheme && !allowedSchemes.includes(scheme as UrlScheme)) return { urlInvalid: true };

    // No scheme provided, but required
    if (!scheme && !allowNoScheme) return { urlInvalid: true };

    // All validations passed
    return null;
  };
}

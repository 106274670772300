import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { type CompanyLocation } from '@core/models/location';

import { LocationSelectorComponent } from '../../../core/components/location-selector/location-selector.component';
import { TooltipComponent } from '../../../core/components/tooltip/tooltip.component';
import { CustomFormElement, type CustomFormElementInstance } from '../../models/form-element';

@Component({
  selector: 'fb-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TooltipComponent, LocationSelectorComponent, FormsModule],
})
export class LocationsComponent implements CustomFormElementInstance {
  @Input() element: CustomFormElement;
  @Input() valueChangeFunc: (value: CompanyLocation) => void;
  @Input() disabled: boolean;
  @Input() controlName: string;

  private _value: CompanyLocation;

  public get value(): CompanyLocation {
    return this._value;
  }

  public set value(val: CompanyLocation) {
    this._value = val;
    this.valueChangeFunc(val);
  }

  public getValue(): CompanyLocation {
    return this._value;
  }

  public setValue(value: CompanyLocation): void {
    this._value = value;
  }
}

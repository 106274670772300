import { Component, ViewChild } from '@angular/core';

import { FormHostDirective, FormHostDirective as FormHostDirective_1 } from '@core/directives/form-host.directive';

@Component({
  selector: 'fb-form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss'],
  standalone: true,
  imports: [FormHostDirective_1],
})
export class FormRowComponent {
  @ViewChild(FormHostDirective, { static: true })
  appFormHost: FormHostDirective;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';

import { DatePickerComponent } from '@core/components/date-picker/date-picker.component';
import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';
import { LocationSelectorComponent } from '@core/components/location-selector/location-selector.component';
import { ProductCategoriesSelectorComponent } from '@core/components/product-categories-selector/product-categories-selector.component';
import { RichTextEditorComponent } from '@core/components/rich-text-editor/rich-text-editor.component';
import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import { SectorsSelectorComponent } from '@core/components/sectors-selector/sectors-selector.component';
import { SelectComponent } from '@core/components/select/select.component';
import { TooltipComponent } from '@core/components/tooltip/tooltip.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { DraggableDirective } from '@core/directives/draggable.directive';
import { DroppableDirective } from '@core/directives/droppable.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { FormHostDirective } from '@core/directives/form-host.directive';

import { NetworkModule } from '../network/network.module';
import { FieldEditFormComponent } from './components/field-edit-form/field-edit-form.component';
import { FormElementContainerComponent } from './components/form-element-container/form-element-container.component';
import { FormRowComponent } from './components/form-row/form-row.component';
import { RenderedFormElementComponent } from './components/rendered-form-element/rendered-form-element.component';
import { CustomFormsService } from './custom-forms.service';
import { ContactsComponent } from './elements/contacts/contacts.component';
import { DateComponent } from './elements/date/date.component';
import { ImageComponent } from './elements/image/image.component';
import { LocationsComponent } from './elements/locations/locations.component';
import { LongTextInputComponent } from './elements/long-text-input/long-text-input.component';
import { MultiSelectComponent } from './elements/multi-select/multi-select.component';
import { NumberInputComponent } from './elements/number-input/number-input.component';
import { ProductCategoriesComponent } from './elements/product-categories/product-categories.component';
import { SectionHeaderComponent } from './elements/section-header/section-header.component';
import { SectorsComponent } from './elements/sectors/sectors.component';
import { SingleSelectComponent } from './elements/single-select/single-select.component';
import { TextComponent } from './elements/text/text.component';
import { TextInputComponent } from './elements/text-input/text-input.component';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { FormRendererComponent } from './form-renderer/form-renderer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    NetworkModule,
    AssetSrcDirective,
    TooltipComponent,
    DatePickerComponent,
    ScrollableAreaComponent,
    FocusableDirective,
    DroppableDirective,
    FormHostDirective,
    SelectComponent,
    RichTextEditorComponent,
    DraggableDirective,
    FormErrorMessagesComponent,
    LocationSelectorComponent,
    NgxMaskDirective,
    ProductCategoriesSelectorComponent,
    SectorsSelectorComponent,
    FormBuilderComponent,
    SectionHeaderComponent,
    TextComponent,
    FormElementContainerComponent,
    TextInputComponent,
    LongTextInputComponent,
    ImageComponent,
    SingleSelectComponent,
    MultiSelectComponent,
    ContactsComponent,
    LocationsComponent,
    DateComponent,
    NumberInputComponent,
    FieldEditFormComponent,
    FormRendererComponent,
    RenderedFormElementComponent,
    SectorsComponent,
    ProductCategoriesComponent,
    FormRowComponent,
  ],
  providers: [CustomFormsService],
  exports: [FormBuilderComponent, FormRendererComponent, TextInputComponent],
})
export class CustomFormsModule {}

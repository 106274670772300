import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { type CompanyContact } from '@clover/network/models/contact';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';

import { TooltipComponent } from '../../../core/components/tooltip/tooltip.component';
import { ContactSelectorComponent } from '../../../network/components/contact-selector/contact-selector.component';
import { CustomFormElement, type CustomFormElementInstance } from '../../models/form-element';

@Component({
  selector: 'fb-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TooltipComponent, ContactSelectorComponent, FormsModule],
})
export class ContactsComponent implements CustomFormElementInstance {
  @Input() element: CustomFormElement;
  @Input() valueChangeFunc: (value: CompanyContact) => void;
  @Input() disabled: boolean;
  @Input() controlName: string;

  private _value: CompanyContact;

  public get value(): CompanyContact {
    return this._value;
  }

  public set value(val: CompanyContact) {
    this._value = val;
    this.valueChangeFunc(val);
  }

  public getValue(): CompanyContact {
    return this._value;
  }

  public setValue(value: CompanyContact): void {
    this._value = value;
  }
}

<div class="form-builder_control-label">
  <span>{{ element.title }}</span>
  @if (element.isLinked) {
    <img appAssetSrc="assets/svg/custom-forms/link-icon.svg" />
  }
  @if (element.helpText) {
    <app-tooltip
      [contentTemplate]="tooltip"
      class="form-builder_tooltip"
      theme="black"
    >
      <div class="tooltip-icon"></div>
    </app-tooltip>
  }

  <ng-template #tooltip>
    <div class="form-builder_tooltip-content">{{ element.helpText }}</div>
  </ng-template>
</div>
<img appAssetSrc="assets/svg/custom-forms/image-placeholder.svg" />
